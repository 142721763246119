<!--
 * @Description: 自建课程
 * @Author: xiawenlong
 * @Date: 2021-04-25 17:36:53
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-19 10:02:27
-->
<template>
  <div class="add-course-by-self">
    <table-list
      title="课程列表"
      :loading="loading"
      :data="courseList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{
        selection: true,
        reserveSelection: true,
        rowKey: 'courseId',
        selectable: selectEvent,
      }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '确认添加课程',
    method: _this.handleAdd,
    type: 'primary',
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '课程名称',
    prop: 'keyword',
  },
  {
    label: '课程类型',
    type: 'select',
    prop: 'courseType',
    children: [
      { value: 0, label: '录播' },
      { value: 1, label: '直播' },
    ],
  },
  // {
  //   label: '课程状态',
  //   type: 'select',
  //   prop: 'enable',
  //   children: [
  //     { value: 0, label: '禁用' },
  //     { value: 1, label: '启用' },
  //   ],
  // },
  {
    label: '上传时间',
    type: 'picker',
    prop: 'createTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'courseId',
    label: '课程编号',
  },
  {
    prop: 'mobile',
    label: '课程封面',
    render: (h, { row }) => {
      return [
        row.pic
          ? h('img', {
              domProps: { src: row.pic },
              style: 'height: 50px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'courseType',
    label: '课程类型',
    formatter: row => {
      return { 0: '录播', 1: '直播', 9: '未知' }[row.courseType]
    },
  },
  {
    prop: 'teacherName',
    label: '任课老师',
  },
  {
    prop: 'chapterCount',
    label: '章节数',
  },
  {
    prop: 'durationCount',
    label: '课程时长（分钟）',
    formatter: row => {
      return row.durationCount
    },
    align: 'right',
    minWidth: 120,
  },
  {
    prop: 'createTime',
    label: '创建时间',
  },
]
import TableList from '@/components/TableList'
import { courseList, publicCourseAdd } from '@/api/course'
import to from 'await-to'
export default {
  name: 'AddCourseBySelf',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      loading: false,
      courseList: [],
      selectCourse: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getCourse()
  },
  methods: {
    async getCourse() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(courseList({ enable: 1, current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.createTime) {
        queryParams.startTime = queryParams.createTime[0]
        queryParams.endTime = queryParams.createTime[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.getCourse()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectCourse = val
    },
    async handleAdd() {
      if (!this.selectCourse.length) return this.$message.warning('请先选择课程')
      const openCourseIds = this.selectCourse.map(item => item.courseId)
      const [, err] = await to(publicCourseAdd({ openCourseIds }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.$router.push(`/course/public`)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourse()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourse()
    },
    selectEvent(row) {
      return !row.openCourseFlag
    },
  },
}
</script>
<style lang="scss" scoped>
.add-course-by-self {
  ::v-deep.table-list {
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      display: none;
    }
  }
}
</style>
